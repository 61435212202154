<template>
    <div class="fans">
        <NavTab title="粉丝"></NavTab>
         <FollowOrFans :list="fansList" :showRightContent="true"></FollowOrFans>
    </div>
</template>

<script>
import NavTab from '@/components/Mobile/NavTab'
import FollowOrFans from '@/components/Mobile/FollowOrFans'
import { mapState } from 'vuex';
export default {
    name: 'Fans',
    components:{
        NavTab,
        FollowOrFans,
    },
    data() {
        return {
            fansList:[],
        };
    },
   computed:{
    ...mapState(['userInfo'])
   },
    activated() {
        this.getFansList();
    },

    methods: {
        // 获取粉丝列表
      async  getFansList()
        {
            try {
                 let user_id = +this.$store.state.userInfo.id;
            let {data:res} = await this.api
        .postFormAPISM(
          {
            user_id: Number(this.$route.query.user_id) || user_id,
            follow_type:2
          },
          "/user/getuserlist"
        )
        this.fansList = res.data.users;
        }  catch (error) {
                console.log(error);
            }
        }
    },
};
</script>

<style lang="less" scoped>
.fans{
    height: 100vh;
    background: #f8f8f8;
}
</style>